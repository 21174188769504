<template>

    <div class="popup active">

		<div class="notice_popup prj_itv pass hr">
                <div class="close">
                    <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
                </div>
                <div class="tit">프로젝트 담당자 변경</div>
                <div class="prj_name">프로젝트명 '{{projectNm}}'</div>
                <div class="qs"><span class="name">{{currCorpMgrMberNm}}({{currCorpMgrLoginId}})</span> 님을 <div class="change_name"> <span class="name">{{mgrMberNm}}({{mgrMberLoginId}})</span></div> 님으로<br/>변경하시겠습니까?</div>
                <div class="select-btn">
                    <div class="btn" @click="$emit('close', false)">다른 담당자 선택</div>
                    <div class="btn" @click="save()">네, 변경하겠습니다!</div>
                </div>
		</div>
    </div>

</template>

<script>

export default {

	components : {		
	},

	props: ['param'],

	data() {
		return {
			projectSeq : this.param.projectSeq,			
			projectNm : this.param.projectNm,
			
			currCorpMgrMberNm : this.param.currCorpMgrMberNm,
			currCorpMgrLoginId : this.param.currCorpMgrLoginId,

			mgrMberSeq : this.param.mgrMberSeq,		// 선택한 담당자
			mgrMberNm : this.param.mgrMberNm,
			mgrMberLoginId : this.param.mgrMberLoginId,

			parentProjectYn : this.param.parentProjectYn // 부모프로젝트 : Y
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');		
	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {

		changeProjectRecrField(event, val, item){
            // console.log('changeProjectRecrField', event, val, item);
            this.projectRecrField = item || {};
        },

		save() {

			var input = {
				projectSeq	: this.projectSeq,
				mgrMberSeq	: this.mgrMberSeq,

				parentProjectYn : this.parentProjectYn
			}

			this.$.httpPost('/api/prj/adm/updateProjecMgr', input)
				.then(() => {
					this.$emit('close', true);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});	
					

		}
	},
};
</script>
